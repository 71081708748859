import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import Seo from '../components/seo';

export default function ServicesPage() {
    return (
        <Layout>
            <Seo
                title="Services"
                description="Our Service philosophy helps you achieve your financial well-being"
            />
            <div className="intbanner">
                <StaticImage
                    src="../images/tl-int-banner2.jpg"
                    alt="Services"
                    placeholder="dominant_color"
                    layout="fullWidth"
                    quality={100}
                />
            </div>
            <div className="content">
                <div className="container">
                    <div className="grid">
                        <div className="col-lg-12 col-bleed services--content">
                            <h1>
                                Services
                                <span>
                                    Our Service philosophy helps you achieve
                                    your financial well-being.
                                </span>
                            </h1>
                            <h2>Initial Services</h2>
                            <p>
                                Throughlife Financial Solutions prefers to
                                engage with clients seeking long-term
                                relationships and as such does not engage in the
                                one-off provision of advice.
                            </p>
                            <p>
                                All potential clients will receive a
                                complimentary analysis of their existing
                                financial position from which a detailed summary
                                of potential strategies, services and estimated
                                costs will be provided.
                            </p>
                            <p>
                                This initial process may be held over multiple
                                meetings to ensure that all new clients have a
                                clear understanding of the proposed relationship
                                prior to any costs being incurred.
                            </p>
                            <p>
                                Once a client has confirmed that they wish to
                                proceed, we will provide a comprehensive
                                Statement of Advice that details all the
                                proposed strategies, recommendations and
                                outcomes. Upon receipt of the client’s
                                authorisation to proceed with the advice our
                                team will implement all recommendations and our
                                ongoing service relationship will commence.
                            </p>
                            <h2>Ongoing Services</h2>
                            <p>
                                Each of our services are offered initially and
                                on an ongoing basis as part of our complete
                                financial review process. Throughout your life
                                the importance of each of these services will
                                change in line with your goals, financial
                                position and lifestyle needs.
                            </p>
                            <p>
                                We provide Initial and Ongoing Services on the
                                below areas:
                            </p>
                            <ul>
                                <li>Asset and Liability Analysis</li>
                                <li>
                                    Wealth Creation and Retirement Planning
                                    Advice and Management
                                </li>
                                <li>Portfolio Management</li>
                                <li>Superannuation Account Administration</li>
                                <li>Superannuation Contribution Strategies</li>
                                <li>Life Insurance Management</li>
                                <li>Taxation Review and Planning</li>
                                <li>
                                    Centrelink Entitlements Analysis and
                                    Management
                                </li>
                                <li>Estate Planning</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
